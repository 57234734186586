import { ACTIVE_NETWORK,  RPC_URL } from "src/constants";
import { Contract } from "@ethersproject/contracts";
import Web3 from "web3";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import axios from "axios";
import RouterABI from "src/ABI/RouterABI.json";
import FactoryABI from "src/ABI/FactoryABI.json";

export function sortAddress(add) {
  if (add) {
    const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`;
    return sortAdd;
  } else {
    return add;
  }
}
export function fixDecimal(number) {  
  if(number < 1){

  return parseFloat(number).toFixed(countZerosAfterDecimal(number) + 2);
  }else{
    return parseFloat(number).toFixed(2);
    }
}

export function countZerosAfterDecimal(number) {
  const numString = number.toString();
  const decimalIndex = numString.indexOf('.');
  if (decimalIndex === -1) {
      return 0;
  }
  let zeroCount = 0;
  for (let i = decimalIndex + 1; i < numString.length; i++) {
      if (numString[i] === '0') {
          zeroCount++;
      } else {
          break;
      }
  }
  return zeroCount;
}
// "wss://mainnet.infura.io/ws/v3/2f2312e7890d42f5b0ba6e29ef50674d"
//    //testNet
/*****************Admin---------Wallet---------Address*****************/
export const AdminOwner = "0xbBEBFCd82cdd310707fc23C804543e487eAE3b9a";
export const ExtimatedGasLimit = 350000;
export const mainnetRPCURL =
  "https://mainnet.infura.io/v3/9a0c6748eb99443b989dcf81467233c4";

// // MainNet
export const mainnetRPCURL1 =
  "https://mainnet.infura.io/v3/9a0c6748eb99443b989dcf81467233c4";

export const UniswapRouter = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";
export const UniswapFactory = "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f";

export const ShibaswapRouter = "0x03f7724180AA6b939894B5Ca4314783B0b36b329";
export const ShibaswapFactory = "0x115934131916C8b277DD010Ee02de363c09d037c";

export const ShushiswapRouter = "0xd9e1cE17f2641f24aE83637ab66a2cca9C378B9F";
export const ShushiswapFactory = "0xC0AEe478e3658e2610c5F7A4A2E1777cE9e4f2Ac";

export const ExchangeContract = "0x80c2Ca093101d8068c21Da6F23b395280a7dC2bc"; // Testing mainNet
export const ArbitrageContract = "0xea652f26ac823d6a3cb9b342ef9b846674ed4cd0"; // MATIC mainNet

export const SwapExchange = [
  {
    exchange: UniswapRouter,
    exchangeFactory: UniswapFactory,
    exchangeName: "Uniswap",
  },
  {
    exchange: ShibaswapRouter,
    exchangeFactory: ShibaswapFactory,
    exchangeName: "Shibaswap",
  },
  {
    exchange: ShushiswapRouter,
    exchangeFactory: ShushiswapFactory,
    exchangeName: "Sushiswap",
  },
];

// export const mainnetRPCURL =
//   "https://mainnet.infura.io/v3/57540dc9529b42be9ce2c6eeda97cdbc";
// export const UniswapRouter = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";
// export const UniswapFactory = "0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f";

// export const ShibaswapRouter = "0xbe2B7BC93EF34994E5d7342cF3c8cC9f1fd51109";
// export const ShibaswapFactory = "0x4fa1932a7fd78C2446842dc00567bFF1D192DB07";

// export const ShushiswapRouter = "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506";
// export const ShushiswapFactory = "0xc35DADB65012eC5796536bD9864eD8773aBc74C4";

// export const ExchangeContract = "";

export const ZeroAddress = "0x0000000000000000000000000000000000000000";

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export const getWeb3Provider = async () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  return httpProvider;
};

export const getWeb3Obj = async () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = await new Web3(httpProvider);
  return web3;
};
export const getEtherobj = async () => {
  const httpProvider = new ethers.providers.JsonRpcProvider(RPC_URL);
  // const web3 = await new Web3(httpProvider);
  return httpProvider;
};
export const getWeb3Obj12 = async () => {
  const httpProvider = new Web3.providers.HttpProvider(
    "https://hidden-misty-rain.discover.quiknode.pro/68a14255f544c207c72bc83400a88f6c1ad235a7"
  );
  const web3 = await new Web3(httpProvider);
  return web3;
};

export const getWeb3ContractObject = async (abi, contractAddress) => {
  const web3 = await getWeb3Obj();
  const contract = await new web3.eth.Contract(abi, contractAddress);
  return contract;
};

export const getBalanceOf = async (abi, address, account) => {
  try {
    const contract = await getWeb3ContractObject(abi, address);
    const balanceOf = await contract.methods.balanceOf(account).call();
    return balanceOf.toString();
  } catch (error) {
    console.log("ERROR", error);

    return 0;
  }
};

export function fromWeiDecimals(balanceOfWei, decimals) {
  if (balanceOfWei) {
    const balanceOfFromWei =
      Number(balanceOfWei.toString()) / 10 ** Number(decimals);  
    return balanceOfFromWei;
  } else {
    return balanceOfWei;
  }
}
export function toWeiDecimals(balanceOfWei, decimals) {
  if (balanceOfWei) {
    const balanceOfFromWei = ethers.utils.parseUnits(
      balanceOfWei.toString(),
      decimals
    );
    return balanceOfFromWei.toString();
  } else {
    return balanceOfWei;
  }
}

export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};
export function copyTextByID(id) {
  var copyText = document.getElementById(id);
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */
  navigator.clipboard.writeText(copyText.value);
  toast.info(`Copied ${copyText.value}`);
}

export const swichNetworkHandler = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
    });
  } catch (error) {
    console.log("ERROR", error);
    if (error.code === 4902) {
      addNetworkHandler();
    }
  }
};

export const addNetworkHandler = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: NetworkDetails,
    });
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const NetworkDetails = [ 
  {
    chainId: "0xA4B1",
    icom: "images/network/arbiteum.webp",
    chainName: "Arbitrum One Network",
    nativeCurrency: {
      name: "Arbitrum One ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://arbitrum.drpc.org"],
    blockExplorerUrls: ["https://etherscan.io"],
  },
];
export const EstimateGasHandler = async (ABI, ContractAddress, Address) => {
  try {
    const myContract = await getWeb3ContractObject(ABI, ContractAddress);
    const estimateGas = myContract.methods
      .callTriangularArbitrage()
      .estimateGas({ from: Address })
      .then(function (gasAmount) {
        console.log("gasAmount------", gasAmount);
      })
      .catch(function (error) {
        console.log("error------", error);
      });

    console.log("estimateGas------", estimateGas);
  } catch (error) {
    console.log("error------", error);
  }
};
export const amountGetout = async (
  SwapRouterF,
  SwapFactoryF,
  amoutInWei,
  TokenA,
  TokenB
) => {
  try {

    let amountOut = 0;
    const pancakeRouterContract = await getWeb3ContractObject(
      RouterABI,
      SwapRouterF,
      mainnetRPCURL
    );
    const pancakeFactoryContract = await getWeb3ContractObject(
      FactoryABI,
      SwapFactoryF,
      mainnetRPCURL
    );

    const web3 = await getWeb3Obj();

    const GetPair = await pancakeFactoryContract.methods
      .getPair(TokenA, TokenB)
      .call();
 // eslint-disable-next-line
    if (GetPair != ZeroAddress) {
      const youGetPrice = await pancakeRouterContract.methods
        .getAmountsOut(amoutInWei, [TokenA, TokenB])
        .call();
      console.log("1 amountGetout ---------- youGetPrice ------", youGetPrice);
      let amountOut1 = web3.utils.fromWei(youGetPrice[1], "ether");
      amountOut = youGetPrice[1];
      console.log("1 amountGetout ---------- amountOut1 ------1", amountOut1);
      // return finaldata;
    } else {
      toast.warn("No liquidity found");
    }
    // console.log(" amountGetout ---------- amountOut ------", amountOut);
    return amountOut;
  } catch (error) {
    console.log(" amountGetout ---------- error------", error);
  }
};
export const APIGasHandler = async () => {
  try {
    const estimateGas = await axios.get(`https://api.etherscan.io/api`, {
      params: {
        module: "gastracker", //
        action: "gasoracle", //
        apikey: "D6BJ2PSTWXGWNGT86SIPDBFTRZPBK5P35H", //
      },
    });
    let SafeGasPrice = estimateGas.data.result.SafeGasPrice;
    // console.log(
    //   "----cross-exchange----- 217 <<<<<< estimateGas >>>>>>",
    //   SafeGasPrice
    // );
    return SafeGasPrice;
  } catch (error) {
    console.log("----cross-exchange----- 217 <<<<<< error >>>>>>", error);
  }
};
export const APITokenPriceHandler = async (tokenAddress, exchangeAddress) => {
  try {
    const res = await axios.get(
      `https://deep-index.moralis.io/api/v2/erc20/${tokenAddress}/price?chain=eth`,
      {
        params: {
          exchange: exchangeAddress, // The factory name or address of the token exchange
        },
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY":
            "RTkPg83QseS4UxY6U1nC5VGMj8nDj6TZHJ064xzrRdqmUiL8StdmzqCWJqucBA4A",
        },
      }
    );
    return res.data.usdPrice;
  } catch (error) {
    console.log("APITokenPriceHandler----------error------", error);
  }
};
export const GenerateEstimateGas = async (CallArbitrageGasLimits, account) => {
  try {
    // const web3 = await getWeb3Obj();
     var gas_estimate = await CallArbitrageGasLimits.estimateGas({
      from: account,
    });
    gas_estimate = Math.round(gas_estimate * 1.2);
    return gas_estimate;
    // return res.data.usdPrice;
  } catch (error) {
    console.log(" error ", error);
  }
};
