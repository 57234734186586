import React, { createContext, useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { SUPPORTED_WALLETS } from "src/connectors";
import { toast } from "react-toastify";
import { injected } from "src/connectors";
import axios from "axios";
// import ApiConfig from "src/config/APICongig";
import { getAPIHandler } from "src/config/service";
import ApiConfig from "src/config/APICongig";

export const UserContext = createContext();

// const setSession = (userAddress) => {
//   if (userAddress) {
//     sessionStorage.setItem("userAddress", userAddress);
//   } else {
//     localStorage.removeItem("userAddress");
//   }
// };

const setSession = ( outToken, clientToken) => {
  console.log(" ----- response?.data?.result?.outToken ", outToken)
  console.log(" ----- response?.data?.result?.clientToken ", clientToken)
  if (outToken) {
    localStorage.setItem("outToken", outToken);
  } else {
    // localStorage.removeItem("outToken");
    // delete axios.defaults.headers.common.Authorization;
  }
  if (clientToken) {
    localStorage.setItem("clientToken", clientToken);
  } else {
    // localStorage.removeItem("clientToken");
    // delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("outToken");
  return accessToken ? true : false;
}
// function checkLogin() {
//   const accessToken = window.localStorage.getItem("token");
//   return accessToken ? true : false;
// }
export default function AuthProvider(props) {
  const { activate, account, library, chainId, deactivate } = useWeb3React();
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [isMetaMask, setIsMetaMask] = useState(false);
  const [userData, setUserData] = useState({});
  const [wallectLoader, setWallectLoader] = useState(false);
  const [walletErr, setWallectErr] = useState(false);

  const disconnectWallte = async () => {
    deactivate();
  };

  // const userAddress = window.localStorage.getItem("token");
  // useEffect(() => {
  //   if (userAddress) {
  //     data.connectWallet(userAddress);
  //     console.log("hghjg", userAddress);
  //   }
  // }, [userAddress]);

  // const userNFTListHadler = async (balanceOf, cancelTokenSource) => {
  //   setUserNFTList([]);
  //   const contract = getContract(
  //     NftContractAddress,
  //     GenerativeNFTABI,
  //     library,
  //     account
  //   );

  //   try {
  //     for (let i = 0; i < balanceOf; i++) {
  //       const id = await contract.tokenOfOwnerByIndex(account, i);
  //       const filter = await contract.tokenURI(id.toString());
  //       const res = await axios.get(filter, {
  //         cancelToken: cancelTokenSource && cancelTokenSource.token,
  //       });
  //       if (res.status === 200) {
  //         setUserNFTList((prev) => [
  //           ...prev,
  //           { id: id.toString(), nfdData: res.data },
  //         ]);
  //       }
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };
  // useEffect(() => {
  //   if (account) {
  //     setWallectLoader(false);
  //   }
  // });

  // useEffect(() => {// eslint-disable-next-line
  //   if (chainId != ACTIVE_NETWORK) {
  //     // swichNetworkHandler();
  //   }
  // }, [chainId]);

  // const getBalanceOf = async (abi, address, account) => {
  //   try {
  //     const contract = await getWeb3ContractObject(abi, address);
  //     const balanceOf = await contract.methods.balanceOf(account).call();

  //     return balanceOf.toString();
  //   } catch (error) {
  //     console.log("ERROR--", error);
  //     return 0;
  //   }
  // };

  // async function getBalanceOfFun() {
  //   setBalanceOfValue(
  //     await getBalanceOf(GenerativeNFTABI, NftContractAddress, account)
  //   );
  // }

  // const getCurrentMintingDetails = async () => {
  //   try {
  //     const web3 = await getWeb3Obj();

  //     const contractObj = await getWeb3ContractObject(
  //       GenerativeNFTABI,
  //       NftContractAddress
  //     );
  //     if (account) {
  //       getBalanceOfFun();
  //     }

  //     const preSaleActive = await contractObj.methods.preSaleActive().call();
  //     setPreSaleActive(preSaleActive);
  //     const publicSaleActive = await contractObj.methods
  //       .publicSaleActive()
  //       .call();

  //     setPublicSaleActive(publicSaleActive);
  //     const NFT_PRICE = await contractObj.methods.getNFTPrice(1).call();
  //     const getNFTPrice = web3.utils.fromWei(NFT_PRICE.toString());
  //     setNftPrice(getNFTPrice);

  //     const MAX_NFT_SUPPLY = await contractObj.methods.MAX_NFT_SUPPLY().call();
  //     setMAX_NFT_SUPPLY(Number(MAX_NFT_SUPPLY.toString()));

  //     const totalSupply = await contractObj.methods.totalSupply().call();

  //     setTotalSupply(Number(totalSupply.toString()));
  //   } catch (error) {
  //     console.log("ERROR", error);
  //   }
  // };

  // const getContractDetails = async () => {
  //   try {
  //     const contractObj = await getWeb3ContractObject(
  //       GenerativeNFTABI,
  //       NftContractAddress
  //     );
  //     const adminAccount = await contractObj.methods.owner().call();
  //     setAdminWalletAddress(adminAccount);
  //   } catch (error) {
  //     console.log("ERROR", error);
  //   }
  // };

  const connectWalletHandler = (data) => {
    try {
      const connector = data.connector;
      console.log(connector.walletConnectProvider?.wc?.uri);
      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }

      activate(connector, undefined, true).catch((error) => {
        if (error) {
          toast.error(JSON.stringify(error.message));
          localStorage.removeItem("walletName");
          activate(connector);
        }
      });
      console.log(connector.walletConnectProvider?.wc?.uri);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name === localStorage.getItem("walletName")
      );
      if (selectectWalletDetails[0]?.data) {
        connectWalletHandler(selectectWalletDetails[0].data);
      }
    } // eslint-disable-next-line
  }, []);

  // const connectWalletAPICall = async () => {
  //   try {
  //     const res = await axios({
  //       method: "POST",
  //       url: ApiConfig.connectWallet,
  //       data: {
  //         walletAddress: account,
  //       },
  //     });
  //     if (res.data.responseCode === 200) {
  //       setIsLogin(true);
  //     } else {
  //       toast.error(res.data.responseMessage);
  //     }
  //   } catch (error) {
  //     console.log("ERROR", error);
  //     toast.error(error.message);
  //   }
  // };

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line
  // useEffect(() => {
  //   if (account) {
  //     connectWalletAPICall();
  //   } else {
  //     setIsLogin(false);
  //     setTokenSession(null);
  //   }
  // }, [account]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (window.localStorage.getItem("outToken")) {
      getProfileHandler(source);
    }
    return () => {
      source.cancel();
    }; // eslint-disable-next-line
  }, [window.localStorage.getItem("outToken")]);

  const getProfileHandler = async (source) => {
    try {
      setUserData({});
      const response = await getAPIHandler({
        endPoint: "getProfileUser",
        source: source,
      });
      console.log("userData===>>>", response);
      if (response.data) {
        setUserData(response.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deployTransaction = async (chainId) => {
    try {
      const contractNames = ["flashLoanSimple", "arbitrageMain", "traingular"];//["polygon", "arbitrum", "binance"];
      let chainName;
      let contract = '0xxxxxxxxxx123434';
      console.log("chainIDdeployTransaction.",chainId)
  
      if (chainId == 80001 || chainId === '80001') {
        chainName = "mumbai";
      } else if (chainId == 137 || chainId === '137') {
        chainName = "polygon";
      } else if (chainId == 42161 || chainId === '42161') {
        chainName = "arbitrum";
      } else if (chainId == 56 || chainId === '56') {
        chainName = "binance"; 
      }
      const apiEndpoint = ApiConfig.storeContractAddress;
  
      const contractPromises = contractNames.map(async (contractName) => {
        try {
          // const contract = await deploy(contractName, networkName); 
          const response = await axios.put(apiEndpoint, {
            chainName,
            contractName,
            contract,
          }, {
            headers: {
              token: window.localStorage.getItem("outToken") 
            }
          });
          console.log("response",response);
          return response.data;
        } catch (error) {
          console.error(`Error deploying or calling API for contract ${contractName}:`, error);
          throw error;
        }
      });
  
      const results = await Promise.all(contractPromises);
  
      return results;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  let data = {
    account,
    walletErr,
    setWallectErr,
    wallectLoader,
    setWallectLoader,
    library,
    chainId,
    userData,
    isLogin,
    isMetaMask,
    updateUser: (account) => {
      // setSession(account);
    },
    getProfileHandler: () => {
      getProfileHandler();
    },
    // userLogIn: (type, data) => {
    //   setTokenSession(data);
    //   setIsLogin(type);
    // },
    userLogIn: (type, outToken, clientToken) => {
      setSession(outToken, clientToken);
      setIsLogin(type);
    },
    connectWallet: (id) => {
      console.log("-----network");
      activate(injected, undefined, true).
      catch((error) => {
        if (error) {
          activate(injected);
          setIsMetaMask(true);
          console.log("connectWallet")
          // deployTransaction();
          console.log("error---", error);
        }
      });
    },
    dicconectWalletFun: () => {
      disconnectWallte();
    },
  };

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
