import React, { useState, useEffect, useContext } from "react";
import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  Box,
  Container,
  Typography
} from "@material-ui/core";
import { Twitter } from "@material-ui/icons";
import TelegramIcon from "@material-ui/icons/Telegram";
import { BsFacebook } from "react-icons/bs";
import { AiFillYoutube } from "react-icons/ai";
import { BsDiscord } from "react-icons/bs";
import MenuIcon from "@material-ui/icons/Menu";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import { FiSun } from "react-icons/fi";
import { BsFillMoonFill } from "react-icons/bs";
import SettingsContext from "src/context/SettingsContext";
import WalletConnectModal from "src/component/ConnectWallet/WalletConnectModal";
import { UserContext } from "src/context/User";
// import Typography from "@/theme/typography";

const useStyles = makeStyles((theme) => ({
  joinButton: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
  },
  menuButton: {
    fontSize: "17px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "auto",
    color: "#fff",
    padding: "0px 15px",
    margin: "0 5px",
    "&.active": {
      color: "#fff",
    },
    "&[tabIndex]:focus": {
      color: "#fff",
    },

    "&:hover": {
      color: "#fff",
    },

    "@media (max-width: 1280px)": {
      lineHeight: "24px",
      padding: "7px 15px !important",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
  },
  toolbar: {
    display: "flex",
    padding: "10px 0",
    justifyContent: "space-between",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
    },
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "200px",
    marginBottom: "15px",
  },
  drawerContainer: {
    padding: "6px 0px",
    height: "100%",
    // background: "#1a1a1a",
    width: "260px",
    display: "flex",
    // background:"#2B2B2B",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    // color: "#000",
    // position: "absolute",
    // top: "-2px",
    // right: "-10px",
    // fontSize: "25px",
    marginRight: "-19px",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
    minHeight: "60px !important",
  },
  inerrMenu: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    whiteSpace: "nowrap",
    paddingLeft: "25px",
    alignItems: "center",
    // justifyContent: "flex-end",
    justifyContent: "flex-start",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "block",
      padding: "0",
    },
    "& li": {
      "&.active": {
        background: "red",
      },
    },
  },
  menuul: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    alignItems: "center",
    margin: "0",
    height: "50px",
    justifyContent: "space-between",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "block",
      "& .logoDesk": {
        display: "none",
      },
    },

    "& .buttonRound": {
      width: "41px",
      height: "41px",
      borderRadius: "50%",
      color: "#fff",
      marginLeft: "15px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "20px",
      cursor: "pointer",
      border: "2px solid #FFFFFF",
      [theme.breakpoints.down("md")]: {
        marginBottom: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "20px",
      },
      "&:hover": {
        background: "#fff",
        color: "#000",
        // border: "2px solid #000",
      },
    },
  },
  // btnSection: {
  //   "@media(max-width:1279px)": {
  //     display: "flex",
  //     flexDirection: "column",
  //   },
  // },
}));

export default function Header({ buttonClick }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const CloseConnectWallet = () => {
    setOpen(false);
  };

  const {
    toolbar,
    menuul,
    drawerContainer,
    drawericon,
    logoDrawer,
    containerHeight,
    inerrMenu,
    mainHeader,
    menuButton,
    // btnSection,
  } = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  console.log(" ----- user ", user.isLogin);
  const { mobileView, drawerOpen } = state;
  const themeSeeting = useContext(SettingsContext);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1280
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          style={{ paddingLeft: "0px" }}
        >
          <Grid item xs={12} align="center">
            {" "}
            {menuText}{" "}
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img
              className={logoDrawer}
              src="images/logo.png"
              alt=""
              style={{ width: "100px" }}
            />

            {menuText}
            <div style={{ padding: "16px" }}>{connectBtn}</div>

            <Box className="socialTopbar">
              <IconButton
                target="_blank"
                href="https://www.facebook.com/"
                className={classes.socialIcons}
              >
                <BsFacebook
                // className={classes.socialIcons}
                />
              </IconButton>

              <IconButton
                target="_blank"
                href="https://www.twitter.com/"
                className={classes.socialIcons}
              >
                <Twitter
                // className={classes.socialIcons}
                />
              </IconButton>

              <IconButton
                target="_blank"
                href="https://www.telegram.com/"
                className={classes.socialIcons}
              >
                <TelegramIcon
                // className={classes.socialIcons}
                />
              </IconButton>

              <IconButton
                target="_blank"
                href="https://www.telegram.com/"
                className={classes.socialIcons}
              >
                <BsDiscord
                // className={classes.socialIcons}
                />
              </IconButton>

              <IconButton
                target="_blank"
                href="https://www.telegram.com/"
                className={classes.socialIcons}
              >
                <AiFillYoutube
                // className={classes.socialIcons}
                />
              </IconButton>
            </Box>
          </div>
        </Drawer>

        <div>{productLogo}</div>

        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={10} align="right"></Grid>
          {window.location.pathname !== "/login" && (
            <Grid item xs={2} align="right">
              <IconButton
                className={drawericon}
                {...{
                  edge: "start",
                  color: "inherit",
                  "aria-label": "menu",
                  "aria-haspopup": "true",
                  onClick: handleDrawerOpen,
                }}
              >
                <MenuIcon
                  width="60px"
                  height="60px"
                  style={{ color: "#fff", fontSize: "30px" }}
                />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    );
  };

  const productLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const connectBtn = <Box></Box>;

  const menuText = (
    <nav>
      <ul class={menuul}>
        <div className="logoDesk">
        <Box style={{ display: "flex" }}>

        {productLogo}
        {/* <Typography variant="h4"  style={{marginTop:"1.3rem"}}>Logo</Typography> */}
        
</Box>
          
        </div>
       
        <li>
          {window.location.pathname !== "/login" && (
            <li className="activecolor">
              <ul className={inerrMenu}>
                {themeSeeting.settings.theme === "DARK" && (
                  <Box className="themeButton">
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        changeTheme("LIGHT");
                      }}
                    >
                      <FiSun />
                    </IconButton>
                  </Box>
                )}
                {themeSeeting.settings.theme !== "DARK" && (
                  <Box className="themeButton1">
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        changeTheme("DARK");
                      }}
                    >
                      <BsFillMoonFill />
                    </IconButton>
                  </Box>
                )}
                <li>
                  {" "}
                  <p className={menuButton} onClick={() => history.push("/")}>
                    {" "}
                    Home{" "}
                  </p>{" "}
                </li>
                <li>
                  {" "}
                  <p
                    className={menuButton}
                    onClick={() => history.push("/about")}
                  >
                    {" "}
                    About{" "}
                  </p>{" "}
                </li>
                {/* <li>
                  {" "}
                  <p
                    className={menuButton}
                    onClick={() => history.push("/dashboard")}
                  >
                    {" "}
                    Dashboard{" "}
                  </p>{" "}
                </li> */}
                {user.isLogin && (
                  <li>
                    {" "}
                    <p
                      className={menuButton}
                      onClick={() => history.push("/dashboard")}
                    >
                      {" "}
                      Dashboard{" "}
                    </p>{" "}
                  </li>
                )}

                {!user.isLogin && (
                  <li style={{ marginLeft: "15px" }}>
                    {window.location.pathname !== "/login" && (
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        target="_blank"
                        style={{ minWidth: "120px" }}
                        className={classes.joinButton}
                        onClick={() => history.push("/login")}
                      >
                        {" "}
                        Login
                      </Button>
                    )}
                  </li>
                )}
              </ul>
            </li>
          )}
        </li>
      </ul>
    </nav>
  );

  return (
    <>
      <AppBar
        className="headerNav"
        elevation={0}
        style={{ borderRadius: "0px" }}
      >
        <Container maxWidth="lg" className={containerHeight}>
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
      {/* <Dialog open={open} onClose={CloseConnectWallet} fullWidth maxWidth="sm">
        <WalletConnectModal CloseConnectWallet={CloseConnectWallet} />
      </Dialog> */}
      {open && (
        <WalletConnectModal open={open} handleClose={CloseConnectWallet} />
      )}
    </>
  );
}
