/* eslint-disable no-use-before-define */
import React, { useEffect, useContext, useState } from "react";
import { useLocation, matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { AiOutlineLogout } from "react-icons/ai";
import { TbExchange } from "react-icons/tb";
import { AiOutlineTransaction } from "react-icons/ai";
import { MdDashboard } from "react-icons/md";
import { useHistory } from "react-router-dom";
import NavItem from "./NavItem";
import { UserContext } from "src/context/User";
import { toast } from "react-toastify";
import ConfirmationModal from "src/component/ConfirmationModal";

const sections = [
  {
    items: [
      {
        title: "Dashboard",
        title1: "Dashboard",
        icon: MdDashboard,
        img1: "/images/icon/dashboardA.svg", // active
        img2: "/images/icon/dashboardNA.svg", // inactive
        href: "/dashboard",
      },
      {
        title: "Profit Path",
        title1: "Exchange",
        icon: TbExchange,
        img1: "/images/icon/profitactive.svg", // active
        img2: "/images/icon/profitdeactive.svg", // inactive
        href: "/market",
      },
      {
        title: "Profile",
        title1: "Exchange",
        icon: TbExchange,
        img1: "/images/icon/profileactive.svg", // active
        img2: "/images/icon/profileDeactive.svg", // inactive
        href: "/profile",
      },
      {
        title: "Transaction",
        title1: "Transaction",
        icon: AiOutlineTransaction,
        img1: "/images/icon/transactionactive.svg", // active
        img2: "/images/icon/transactiondeactive.svg", // inactive
        href: "/transaction",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    //eslint-disable-next-line
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key.toString()}
        open={Boolean()}
        title={item.title}
        title1={item.title1}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        img={item.href === window.location.pathname ? item.img1 : item.img2}
        icon={item.icon}
        info={item.info}
        key={key.toString()}
        title={item.title}
        title1={item.title1}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    borderRadius: "0px !important",
    background:"#2B2B2B"
    // background: theme.palette.primary.main,
  },
  desktopDrawer: {
    width: 200,
    // top: 20,
    borderRadius: "0px !important",
    // height: "calc(100% - 80px)",
    boxShadow: "none",
    // backgroundColor: "#fff",
    background:"#2B2B2B"

  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  logo: {
    width: "auto",
    cursor: "pointer",
    paddingTop: "3px",
    maxWidth: "87px",
    paddingLeft: "11px",
  },
  contentBox: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    background:"#2B2B2B",
    boxShadow:"-19px -52px 53px 0px #41164333",
    backgroundColor: theme.palette.primary.paper,
    [theme.breakpoints.down("md")]: {
      marginTop: "-22px",
    },
  },
  activeLink: {
    background: "#41CEA6",
  },
  nonActiveLink: {
    background: "#FFFFFF08",
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const [isLogout, setIsLogout] = useState(false);
  const user = useContext(UserContext);

  const logoutFunction = () => {
    toast.success("You have been logout successfully!");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("outToken");
    window.localStorage.removeItem("clientToken");
    window.localStorage.removeItem("arbitage");
    user.userLogIn(false, null, null);
    setIsLogout(false);
    history.push("/")
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    } // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box height="100%" display="flex" flexDirection="column">
        <Box className={classes.contentBox}>
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <Box py={4}>
              {sections.map((section, i) => {
                return (
                  <List
                    key={`menu${i}`}
                    subheader={
                      <ListSubheader disableGutters disableSticky>
                        {section.subheader}
                      </ListSubheader>
                    }
                  >
                    {renderNavItems({
                      items: section.items,
                      pathname: location.pathname,
                    })}
                  </List>
                );
              })}

              <Box
                className="displayStart"
                style={{ marginLeft: "32px", cursor: "pointer" }}
                onClick={() => setIsLogout(true)}
              >
                <img src="/images/icon/logout.svg" alt="Logo" />
                <Typography
                  variant="body2"
                  style={{
                    color: "#fff",
                    marginLeft: "12px",
                    fontWeight: "400",
                    fontSize: "15px",
                  }}
                >
                  Logout
                </Typography>
              </Box>
              <Box
                style={{ position: "absolute", bottom: "60px",width:"100%" }}
              >
                  <Box className={location.pathname === '/faqs' ? classes.activeLink : classes.nonActiveLink}>
                <Typography
                  variant="body2"
                  onClick={() => history.push("/faqs")}
                  style={{
                    color:
                      window.location.pathname == "/faqs"
                        ? "#fff"
                        : "#fff",
                    cursor: "pointer",
                    fontWeight: "400",
                    fontSize: "15px",
                    marginBottom: "10px",
                    marginLeft:"45px"
                  }}
                >
                  FAQs
                </Typography>
                </Box>
                <Box className={location.pathname === '/policy' ? classes.activeLink :  classes.nonActiveLink}>
                <Typography
                  variant="body2"
                  onClick={() => history.push("/policy")}
                  style={{
                    color:
                      window.location.pathname == "/policy"
                        ? "#fff"
                        : "#fff",
                    cursor: "pointer",
                    fontWeight: "400",
                    fontSize: "15px",
                    marginBottom: "10px",
                    marginLeft:"45px"

                  }}
                >
                  Privacy Policy
                </Typography>
                </Box>
                <Box className={location.pathname === '/terms' ? classes.activeLink :  classes.nonActiveLink}>
                <Typography
                  variant="body2"
                  onClick={() => history.push("/terms")}
                  style={{
                    color:
                      window.location.pathname == "/terms"
                        ? "#fff"
                        : "#fff",
                    cursor: "pointer",
                    fontWeight: "400",
                    fontSize: "15px",
                    marginLeft:"45px"

                  }}
                >
                  Terms of Use
                </Typography>
                </Box>
              </Box>
            </Box>
          </PerfectScrollbar>
        </Box>
      </Box>
      {isLogout && (
        <ConfirmationModal
          open={isLogout}
          isLoading={false}
          handleClose={() => {
            setIsLogout(false);
          }}
          title={"Logout"}
          desc={"Are you sure, you want to logout?"}
          handleSubmit={(item) => logoutFunction(item)}
        />
      )}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
     
        >
          <Box>
          <img
            src="./images/logo.png"
            alt="image"
            className={classes.logo}
            onClick={() => history.push("/")}
          />
          </Box>
          
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          
          <Box style={{ display: "flex" , alignItems:"center" }}>
          <img
            src="images/logo.png"
            alt="sfsdg"
            className="logosidebarImg"
            onClick={() => history.push("/")}
          />
          {/* <Typography variant="h4"  style={{marginTop:"1rem"}}>Logo</Typography> */}
          </Box> 
     
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
