import React from "react";
import { Typography, Dialog, Button, Box, IconButton } from "@material-ui/core";
import { AiOutlineClose } from "react-icons/ai";




export default function ConfirmationModal({
  title,
  desc,
  isLoading,
  open,
  handleClose,
  handleSubmit,
}) {
  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isLoading) {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="xs"
      backgrounColor=" #20211E"
      

    >
      <Box style={{ position: "absolute", top: "0px", right: "0px" }}>
        <IconButton onClick={handleClose}>
          <AiOutlineClose color="#fff" />
        </IconButton>
      </Box>

      <Box align="center">
        <img
          src="/images/logout.png"
          alt="logoImage"
          width="50px"
          height="50px"
        />
      </Box>
      <Box align="center" my={1}>
        <Typography variant="h3" style={{ color: "#fff" }}>
          {title}
        </Typography>
      </Box>
      <Box align="center" mb={2}>
        <Typography variant="h6" style={{ color: "#fff" }}>
          {desc}
        </Typography>
      </Box>

      <Box my={2} align="center" className="displayCenter">
        <Button
          disabled={isLoading}
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => {
            if (!isLoading) {
              handleClose();
            }
          }}
          style={{
            marginRight: "8px",
            borderRadius: "5px",
            color: "#41CEA6",
            border: "1px solid #41CEA6",
            "&:hover":{
              backgrounColor:"#41CEA6 !important"
            }
          }}
        >
          No
        </Button>
        <Button
          disabled={isLoading}
          variant="contained"
          color="primary"
          className="yestlogoutButton"
          fullWidth
          onClick={() => {
            handleSubmit();
          }}
          style={{ marginleft: "8px" }}
        >
          Yes
        </Button>
      </Box>
    </Dialog>
  );
}
