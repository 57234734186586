import React, { createContext, useState } from "react";
import axios from "axios";

export const AuthContext = createContext();

const setSession = ( outToken, clientToken) => {
  if (outToken) {
    sessionStorage.setItem("outToken", outToken);
  } else {
    // localStorage.removeItem("outToken");
    // delete axios.defaults.headers.common.Authorization;
  }
  if (clientToken) {
    sessionStorage.setItem("clientToken", clientToken);
  } else {
    // localStorage.removeItem("clientToken");
    // delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("clientToken");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData] = useState({});

  let data = {
    userLoggedIn: isLogin,
    userData,
    userLogIn: (type, outToken, clientToken) => {
      setSession(outToken, clientToken);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
