import React, { useState, useContext } from "react";
import clsx from "clsx";
import SearchField from "src/component/SearchField";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Badge,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Avatar,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { UserContext } from "src/context/User";

const useStyles = makeStyles((theme) => ({
  connectWallets: {
    background: "#fff !important",
    color: "#000 !important",
    border: "1px solid rgba(65, 22, 67, 1) !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px !important",
      padding: "9px 20px !important",
    },
  },
  disconnectWallet: {
    color: "rgba(65, 22, 67, 1)",
    background: "rgba(255, 255, 255, 1) !important",
    border: "1px solid rgba(65, 22, 67, 1) !important",
    fontWeight: "500",
    fontSize: "16px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px !important",
      padding: "9px 20px !important",
    },
  },
  root: {
    borderRadius: "0px !important",
    backgroundColor: "#fff",
    boxShadow: "0 0 40px 0 rgb(94 92 154 / 6%) !important",
  },
  notificationBox: {
    "&:hover": {
      background: "rgb(8, 86, 204) !important",
    },
  },
  toolbar: {
    height: 80,
    "& .MuiIconButton-root": {
      padding: "0px",
    },
  },
  logo: {
    width: "134px",
    cursor: "pointer",
    height: "auto",
    paddingTop: "21px",
    paddingLeft: "20px",
  },
  logo1: {
    cursor: "pointer",
    paddingLeft: "16px",
    maxWidth: "100px",
    padding: "20px",
    marginTop: "76px",
    marginBottom: "21px",
    paddingBottom: "63px",

    "@media (max-width: 1279px)": {
      display: "none",
    },
  },

  handleDialog: {
    "@media (max-width:500px)": {
      minwidth: "200px",
    },
    "& .MuiDialog-paperScrollPaper": { maxHeight: "78vh" },
    "& .walletheading": {
      width: " 500px",
      margin: 0,
      display: "flex",
      alignItems: " center",
      justifyContent: "space-between",
      borderBottom: " 1px solid #cecece",
      padding: " 5px",
      paddingBottom: "20px",
      fontSize: "14px",
      color: "#000",
      position: "relative",
      [theme.breakpoints.down("md")]: {
        width: " 100%  ",
      },
      "& span": {
        position: "absolute",
        bottom: 3,
        right: 5,
        fontSize: "12px",
        color: "#9e9e9e",
      },
    },
    "& .notificationexpand": {
      textAlign: "center",
    },
    "& .MuiDialogContent-root": {
      "@media (max-width:500px)": {
        width: "307px",
      },
      "@media (max-width:380px)": {
        width: "250px",
      },

      "@media (max-width:350px)": {
        width: "210px",
      },
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      justifyContent: "center",
    },
    "& .MuiDialog-container": {
      position: "absolute",
      right: 1,
      top: "6%",
    },
    "& .MuiDialog-scrollPaper": {
      display: "flex",
      alignItems: "start",
      justifyContent: "center",
    },
  },
  namePlace: {
    "& .MuiDialog-container": {
      maxHeight: "78%",
      minWidth: "214",
    },
  },
  handleShow: {
    display: "block",
  },
  divide: {
    height: "45px",
    margin: "0 32px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px 10px",
    },
  },
  btn: {
    "& .MuiIconButton-root": {
      padding: "12px",
      [theme.breakpoints.down("xs")]: {
        padding: "4px",
      },
    },
  },
  text: {
    fontSize: "15px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData(props) {
  const classes = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();
  const [openSearch, setopenSearch] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false); // eslint-disable-line

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSearch = () => {
    setopenSearch(false);
  };

  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (open1) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]); //eslint-disable-line

  const HiddenShow = async () => {
    var get = document.getElementById("showNotification");
    get.style.display = "none";
    var get1 = document.getElementById("showNotification1");
    get1.style.display = "none";
    var set = document.getElementById("hideNotification");
    set.style.display = "block";
  };

  return (
    <>
      {/* <img
        src="images/logo.svg"
        alt="sfsdg"
        className={classes.logo1}
        onClick={() => history.push("/")}
      /> */}
      <Box className="profilename">
        <Typography
          variant="h6"
          style={{ color: "#ffff", fontWeight: "600", fontSize: "25px" }}
        >
          Dashboard
          <span
            style={{
              fontWeight: "400",
              fontSize: "15px",
              marginLeft: "9px",
              color: "#ffffcc",
            }}
          >
            {" "}
            Welcome back,
          </span>{" "}
        </Typography>
      </Box>
      <Box flexGrow={1} />
      <Badge className={classes.namePlace} color="secondary">
        <Box mr={2}>
          {openSearch && (
            <Dialog
              open={openSearch}
              onClose={() => setopenSearch(false)}
              fullWidth
              maxWidth="sm"
            >
              <SearchField handleCloseSearch={handleCloseSearch} />
            </Dialog>
          )}
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          className={classes.handleDialog}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <p className="headingtext" style={{ color: "#0F212E0F212E" }}>
              Notifications{" "}
            </p>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            ></DialogContentText>
          </DialogContent>
          <DialogActions id="scroll-dialog-title">
            <Box id="showNotification1" className={classes.handleShow}>
              <button onClick={() => HiddenShow()} className="headingtext">
                Expand List{" "}
              </button>
            </Box>
          </DialogActions>
        </Dialog>
      </Badge>
      <Typography
        variant="body1"
        style={{ color: "#14133b", fontWeight: "400" }}
      >
        {user?.userData?.name && user?.userData?.name}
      </Typography>
      {!user.account ? (
        <Button
          variant="contained"
          small
          color="primary"
          className={classes.connectWallets}
          onClick={() => user.connectWallet()}
        >
          Connect Wallet
        </Button>
      ) : (
        <Button
          small
          variant="contained"
          color="primary"
          className={classes.disconnectWallet}
          onClick={() => user.dicconectWalletFun()}
        >
          <span>Disconnect </span>
        </Button>
      )}{" "}
      &nbsp; &nbsp; &nbsp;
      <Badge
        className={classes.namePlace}
        color="secondary"
        onClick={() => history.push("/profile")}
        style={{ cursor: "pointer" }}
      >
        <Avatar
          // style={{ marginRight: "-21px" }}
          className="profileImgaeBox"
          src={
            user?.userData?.profilePic
              ? user?.userData?.profilePic
              : "/images/photo.png"
          }
          style={{ color: "white" ,marginRight: "2rem"}}
        />
      </Badge>
    </>
  );
}
