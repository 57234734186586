export const socketURL = "wss://polyquickswap.blitzforgee.com/";
// https://websocketpolyquickswap-madhubot.mobiloitte.org/
const url = "https://node.blitzforgee.com";
// http://172.16.0.192:1939/
// local 

// export const socketURL = "ws://172.16.0.192:1939";
const user = `${url}/api/v1/user`;
const admin = `${url}/api/v1/admin`; 
const Static =`${url}/api/v1/static/`;
const stake = `${url}/api/v1/stake`;
const nft = `${url}/api/v1/nft`;
const Transactions = `${url}/api/v1`;

const updateSubscribe = `${url}/api/v1/updateSubscribe`;

const ApiConfig = {
  // Signup us

  // signUp: `${user}/signup`,

  createNFT: `${nft}/createNFT`,
  listNFT: `${nft}/listNFT`,
  stakeNFT: `${stake}/stakeNFT`,
  stakeList: `${stake}/stakeList`,
  unstakeNFT: `${stake}/unstakeNFT`,

  userUpdateSubscribe: `${updateSubscribe}/userUpdateSubscribe`,
  // contact us

  addContactUs: `${user}/addContactUs`,
  connectWallet: `${user}/connectWallet`,
  storeContractAddress: `${user}/storeContractAddress`,
  recentProfit :`${user}/recentProfits`,
  updateProfile: `${user}/updateProfile`,
  getProfile: `${user}/getProfile`,
  buyToken: `${user}/buyToken`,
  buyTokenList: `${user}/buyTokenList`,
  graph: `${user}/graph`,
  profitanalysis: `${user}/profitanalysis`,
  buySubscriptionplan: `${user}/buySubscriptionplan`,
  dashboard: `${user}/dashboard`,
  addAutoTradingArbitrage: `${user}/addAutoTradingArbitrage`,

  // admin
  listSubscriptionPlan: `${admin}/listSubscriptionPlan`,
  addSubscriptionPlan: `${admin}/addSubscriptionPlan`,
  deleteSubscriptionPlan: `${admin}/deleteSubscriptionPlan`,
  buySubscriptionPlanAllList: `${admin}/buySubscriptionPlanAllList`,
  viewBuySubscriptionPlan: `${admin}/viewBuySubscriptionPlan`,
  viewSubscriptionPlan: `${admin}/viewSubscriptionPlan`,
  listToken: `${admin}/listToken`,
  getTransactions: `${admin}/getTransactions`,

  //USER AUTH
  signup: `${user}/signup`,
  verifyOTP: `${user}/verifyOTP`,
  login: `${user}/login`,
  forgotPassword: `${user}/forgotPassword`,
  resetPassword: `${user}/resetPassword`,
  resendOtp: `${user}/resendOtp`,
  getProfileUser: `${user}/getProfileUser`,
  changePassword: `${user}/changePassword`,

  //USER AUTH 2FA
  verify2Falogin: `${user}/verify2Falogin`,
  editEmail2FA: `${user}/editEmail2FA`,
  verify2FAOTP: `${user}/verify2FAOTP`,
  enableEmail2FAGoogle: `${user}/enableEmail2FAGoogle`,
  verifyTwoFactorGoogle: `${user}/verifyTwoFactorGoogle`,

  // STATIC
  faqList: `${Static}/faqList`,
  viewStaticContent: `${Static}/viewStaticContent`,
};
export default ApiConfig;
